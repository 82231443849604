.news-detail {
  margin: 1em 0 0;

.news_navigation > .all_news,
.news_navigation > .next_news,
.news_navigation > .prev_news{
  font-size: inherit;
}

  .news-detail__header {
    display: table;
    width: 100%;
  }

  .news-detail__title {
    font-size: 25px;
    display: table-cell;
    padding: 0;
    @include media(md) {
      font-size: 18px;
    }
  }

  .news-detail__date{
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    // width: 85px;
    padding-bottom: 4px;
    & > span {
      background: $linkColor;
      color: #fff;
      padding: 0.5em;
      white-space: nowrap;
      border-radius: $borderRadius;

      &.pbl {
        background: none;
        display: block;
        color: $textColor;
        padding: 0 0 1em;
      }
    }
  }

  .news-detail__text {
    padding: 3em 0;
    @extend .clearfix;
    @include media(md) {
      padding: 1em 0;
    }
    & > a {
      float: left;
      outline: none;
    }
  }
  .news-detail__image {
    display: block;
    margin: 0 1em 0 0;
    border-radius: $borderRadius;
    padding: 0 1em;
  }
}

