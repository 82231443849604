.rating_block {
  position: relative;
}

.rating_block > div {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 5px;
}

.rating_star {
  display: inline-block;
  height: 29px;
  width: 29px;
  vertical-align: middle;
  text-indent: -9999px;
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;

  &:before {
    content: '\f005';
    font: normal 25px/1 "FontAwesome";
    color: #ccc;
    position: absolute;
    top: 3px;
    left: 3px;
    text-indent: 0;
    transition: color 0.2s ease;
  }
}

label.rating_star {
  cursor: pointer;
}

.rating_star.half {

  &:before {
    content: '\f123';
    color: $linkColor--hover;
    transition: color 0.2s ease;
    z-index: 2;
  }

  &:after {
    content: '\f005';
    font: normal 25px/1 "FontAwesome";
    color: $textColor;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
  }
}


.rating_star_full {
  &:before {
    content: '\f005';
    color: $linkColor--hover;
    transition: color 0.2s ease;
    z-index: 2;
  }
}

.rating_link {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  text-decoration: none;
}

.flow_rating {
  position: absolute;
  top: -40px;
  left: 50%;
  margin-left: -78px;
  width: 140px;
  padding: 5px;
  text-align: center;
}

.flow_rating > div {
  display: inline-block;
  line-height: 25px;
  margin: 0 !important;
}

.rate_results {
  color: $textColor;
  padding: 0 1em;
  position: relative;
  min-width: 215px;
  overflow: hidden;
}

.rate_results:before {
  background: $bgColor;
  content: 'Ваш голос учтён!';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  line-height: 24px;
  text-align: right;
  opacity: 0;
  transition:  0.2s ease-in-out;
}

.rate_results._get:before {
  top: 0;
  opacity: 1;
}
