.container {
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;
  @extend .clearfix;
}


main {
  width: 100%;
  > .container{
    background: $white;
  }
  &.market{
    .module-inner{
      &.new{
        border-top: none;
      }
    }
  }
}

aside {
  float: left;
  padding: 0 1em;
  width: 270px;
  overflow: hidden;

  @include media(md) {
    float: none;
    width: 100%;
  }
}

.content-block {
  float: right;
  padding: 0 1em;
  width: calc(100% - 270px);
  min-height:calc(100vh - 99px - 49px - 233px - 48px);

  @include media(md) {
    float: none;
    width: 100%;
  }
}

section{
  padding: 64px 0 0;
  // margin: 1em 0 4em;
  // padding: 0 1em;
  @include media(md) {
    padding: 50px 0 20px;
  }
  &#spec-block{
    background: #efefef;
  }
  &#feedback-block{
  padding: 64px 0 53px;
  background: url(/assets/images/bg-form.jpg) 0 0 no-repeat;
      @include media(md) {
      padding: 0;
    }
  }
  &#tizers{
    padding: 0 0 53px;
    @include media(md) {
      padding: 0 0 20px;
    }
  }
  &#photo-block{
    padding: 64px 0 40px;
    background-image: url(/assets/images/testimonial02.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include media(md) {
      padding: 50px 0 20px;
    }
    h2{
      color: $white;
    }
  }
  &#catalog,
  &#prem_reviews{
    padding: 65px 0 40px;
    background-image: url(/assets/images/testimonial01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
     @include media(md) {
      padding: 15px 0;
    }
  }
  &#catalog{
    h2{
      color: $white;
    }
    p{
      display: inline-block;
      color: $white;
      padding: 0;
      margin: 0;
      max-width: 50%;
      @include media(md) {
        max-width: 100%;
      }
    }
  }
  .container-bootstrap{
    // border-top: 1px solid #ececec;
    // background: $white;
  }
  .module-inner {
    // padding: 0;
    // margin: 0;
  }
  &:not(#landing-list){
    // background: $footerTopBgColor;
  }
  &#landing-list{
  }
  &#catalog{
    // margin: 2em 0 0;
    .module-inner {
      // padding: 1em 0 0;
    }
  }
  &#news-block{
    .container-bootstrap{
      // padding: 0;
      .module-inner{
        // padding: 0 1em;
      }
    }
  }
  &#yamap{
    // margin: 1em 0 0;
    padding: 0;
    background: none;
    .module-inner{
      margin: 0;
    }
  }
  .feedback{
  }
}


#spec-block{
  img{
    display: block;
    margin: 0 auto 20px;
    filter: grayscale(1);
  }
  a{
    display: block;
    text-align: center;
    text-transform: capitalize;
    font-size: 22px;
    margin: 20px 0px 10px;
    text-decoration: none;
    &:hover{
      img{
        filter: none;
      }
    }
  }
}

.mobile,
.tablet{
  section{
    padding: 0;
  }
}

html{
  &.index{
    &.section{
      .content-block{
        width: 100%;
      }
    }
  }
}