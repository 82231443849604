.news-block__title {
	padding: 0 0 10px 0;
	& > a {
		color: $textColor;
		font-size: 22px;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}
}

.news-block__body{
	height: 100%;
	padding: 20px 20px 20px;
	margin: 0 0 1em;
    border: 1px solid rgba(204, 204, 204, 0.5);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    &.no_photo{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    }
}

.news-block__text {
	// font-size: 14px;
	// margin: 0.5em 0;
	// line-height: 1.333;
}

.news-block__date {
	vertical-align: middle;
	margin: 0 0 0.5em;

	& > span {
		background: $linkColor;
		color: #fff;
		font-size: 13px;
		padding: 0 8px 1px;
		white-space: nowrap;
		border-radius: $borderRadius;

		&.news-publish {
			background: none;
			display: none;
			color: $textColor;
			font-size: 10px;
			padding: 0;
		}
	}
}

/* Новости на главной     */
/* ...в левой колонке     */
.news-block {
	&.left-news{
		padding: 0;
		.news-block__image{
			border-radius: 0;
		}
		.news-block__body{
			border: none;
		    padding: 20px 20px 0;
   			margin: 0;
		}
	}

	.news-block__item {
		// margin: 0 0 1em;
		// padding: 0 0 1em;
		// border-bottom: 1px solid $linkColor;
	}


	.news-block__header {
		// display: table;
		// width: 100%;
	}


	.news-block__date,
	.news-block__title {
	}


	.news-block__date {
	}


	.news-block__image {
		display: block;
		object-fit: cover;
		object-position: top;
    	width: 100%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		// @extend .img-responsive;
		// border-radius: $borderRadius;
	}


	.readmore-link {
		display: block;
		padding: 0 0 0.5em;
	}

	.news-all,
	.news-archive {
		padding: 1em 0;
		text-align: center;

		& > a {
			// text-transform: uppercase;
		}
	}

	@include media(xmd) {
		.news {
			width: calc(50% - 1em);

			&:last-child {
				width: 100%;
			}
		}
	}

	@include media(sm) {
		.news {
			width: 100%;
		}
	}
}



/* Новости на главной     */
/* ... в контент области  */
.module-inner {
	.news-block {
		// @extend .clearfix;
		.news-block__item {
			display: flex;
			flex-direction: column;
			height: 100%;
			// float: left;
			// margin: 0 2em 1em 0;
			// width: calc(25% - 1.5em);
			transition: all 0.5s;
			&:hover {
				transform: translateY(-5px);
			}

			&:nth-child(4) {
				// margin: 0 0 1em;
			}
			@include media(md){
				// width: 100%;
			}
		}
	}
}


/* Список новостей - news/page/1 */
.news-list {

	.readmore-link {
		display: block;
		margin: 1em 0 0;
	}

	.news-block__image {
		display: block;
		object-fit: cover;
		object-position: top;
    	width: 100%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.news-block__item {
		padding: 0 0 2em;
		display: flex;
		flex-direction: column;
		height: 100%;
		transition: all 0.5s;
		&:hover{
			    transform: translateY(-5px);
		}
		// @extend .clearfix;

		// border-bottom: 1px solid $borderColor;
		// margin: 0 0 2em;
		// padding: 0 0 1em;

		&:last-child {
			// border-bottom: none;
		}
	}

	.news-block__header {
		display: table;
		width: 100%;

		.news-block__date{
			text-align: right;
		}
		.news-block__date,
		.news-block__title {
			display: table-cell;
		}
	}
}

section{
	.news-block{
		.news-block__item{
			// padding: 1em;
			// background: $white;
			// border: 1px solid $borderColor;
		}
	}
}
