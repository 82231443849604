.index{
	&.desktop{
		&.video_on{
			nav{
				.menu_level_1{
					padding: 0;
				}
				.search{
					padding: 0 0 0 1em;
				}
			}
		}
	}
}

nav {
	@include navtop();
	display: flex;
	// flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	position: relative;
	z-index: 2;

	.menu_level_1 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		// display: table;
		// padding: 0 0 0 1em;
		list-style: none;
		width: 100%;
		@include media(md) {
			display: block;
			text-align: center;
		}

		& > li {
			display: table-cell;
			position: relative;
			vertical-align: top;
			transition: all 0.5s;

			@include media(md) {
				display: inline-block;
			}
			&:hover{
    			transform: translateX(-3px);
			}
			& > a {
				color: #0a0909;
				display: block;
				padding: 0.5em;
				// text-transform: uppercase;
				text-decoration: none;
				text-align: center;
				@include media(md) {
					padding: 0.5em;
				}
			}

			&.selected {
				cursor: default;
				// background: linear-gradient(to bottom, #fe8400 0%, #d14f00 100%);
				& > a {
					font-weight: 500;
					// color: $white;
				}
			}
		}

		a {
			display: block;
			text-decoration: none;
		}


		&.deep-3,
		&.deep-2 {
			& > li {
				&.selected {
					& > a {

						&:hover {
						}
					}
				}
			}
		}


		&.deep-3 {
			.menu_level_2 {
				& > li {
					&.selected {
						& > a {

							&:hover {
							}
						}
					}
				}
			}
		}

	}

	/* Всплывающее меню */
	.menu_level_2 {
		background: #fff;
		box-shadow: none;
		display: table;
		list-style: none;
		margin: -1px 0 0;
		min-width: 150px;
		padding: 0;
		position: absolute;
		opacity: 0;
		top: 100%;
		left: 0;
		z-index: 999;
		text-align: left;
		text-transform: none;
		visibility: hidden;
		border-radius: 0 0 10px 10px;

		& > li {
			display: table-cell;
			padding: 0.5em 1em;

			.menu-item__photo {
				float: left;
				width: 50%;

				& > img {
					display: block;
					height: auto;
					max-width: 100px;
					width: 100%;
				}
			}

			.menu-item__description {
				float: right;
				width: 50%;

				& > span {
					font-size: 12px;
				}
			}

			.menu-item__header {

				& > span {
					color: $textColor;
					font-size: 12px;
					vertical-align: top;
				}
			}

			& > a {
				color: $textColor;
				display: block;
				white-space: nowrap;
			}
			ul {
				display: block;
				list-style: none;
				padding: 0.5em 0.5em 0 1em;

				& > li {
					& > a {
						color: $textColor;
						display: block;
						white-space: nowrap;
						&:hover{
							color: $linkColor--hover;
						}
					}

					&.selected {
						&:before{
							color: $linkColor--hover;
						}
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}
	}
}

.desktop {

	nav {
		.menu_level_1 {
			position: relative;
			width: 100%;

			& > li {

				/* Показываем всплывашку на ховер */
				&.subcat {
					&:hover {
						.menu_level_2 {
							opacity: 1;
							transition: opacity 0.3s ease, transform 0.3s ease-out;
							visibility: visible;
							transform: translate3d(0, 0, 0);
							box-shadow: $module_top__box-shadow;
							// border: 1px solid $borderColor;
						}
					}
				}

				&:not(.selected) {
					&:hover {
						// background: linear-gradient(to bottom, #fe8400 0%, #d14f00 100%);
						& > a {
						}
					}
				}
			}
		}


		.menu_level_2 {
			display: table-row;
			left: 0;
			transform-origin: center;
			transform: translate3d(0, 1em, 0);

			& > li {
				float: left;
				width: 100%;

				&.selected {
					& > a {
						color: $linkColor--hover;
					}
				}

				&:not(.selected) {
					& > a {
						&:hover {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.menu_level_3 {
			& > li {
				position: relative;
				font-size: 13px;
				@include fontawesome('\f105') {
					font-size: 14px;
					color: $textColor;
					left: 0;
				}
				&:not(.selected) {
					&:hover {
						&:before{
							color: $linkColor--hover;
							transition: color 0.3s ease;
						}
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.search {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(0, -50%);
			// float: right;
			// margin: 3px 0 0;
			// padding: 0 1em;
			width: 40px;
			transition: width 0.3s ease;
			&:hover{
				width: 150px;
			}
			@include media(md) {
				top: 0;
				transform: none;
				// margin: 0;
				// width: 100%;
			}
		}

		.menu-wrapper {
			// float: left;
			width: calc(100% - 40px);
			@include media(lg) {
				// width: 100%;
			}
		}
	}
}

