@import "../system/icon";

header{
	background: $headerTopBgColor;
	position: relative;
	&:before{
		display: none;
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 7px;
		background: #0290d4;
		border-top: 1px solid #1297d7;
		border-bottom: 1px solid #1297d7;
	}
	@extend .animated;
	padding: 0.5em 0;
	&.scrolled{
		@extend .animated;
		@extend .fadeInDown;
		background: rgba(255, 255, 255, 1);
		position: fixed;
		z-index: 100;
		width: 100%;
		top: 0;
		padding: 0;
		.wow{
			visibility: visible !important;
		}
		.header-bottom{
			padding: 1em 0 0.5em;
			.header-contacts{
				padding: 0;
			}
			.logo{
				.logo-img{
					max-width: 280px;
				}
				.slogan{
					font-size: 16px;
					padding: 0.4em 0 0 1em;
				}
			}
			.phones-list__item{
				> a{
					line-height: 30px;
				}
			}
			.mail{
				.mail-wrapper{
					margin: 0 0 .5em;
				}
			}
			.times-block{
				.title{
				}
			}
			.header-cart{

				.tofavorite_button{
					padding: 0 5px 0 0;
					top: 5px;
					.count{
						margin: 5px 0 0;
					}
				}
				.cart{
					> .header-cart__
					title{
						display: none;
					}
					&:before{
						font-size: 25px;
					}
				}
			}
			.call-order{
				&.button{
					font-size: 12px;
					padding: 5px 14px;
				}
			}
		}
		nav{
			.menu_level_1{
				> li{
					> a{
						padding: 0.5em;
					}
				}
			}
			.search{
				margin: 0;
				.search-form{
					margin: 4px 0 0;
				}
			}
		}
	}
}
.header-bottom {
	.logo {
		float: $logoPos;
		width: $logoWidth;
		margin: $logoMargin;
		padding: $logoPadding;
		@extend .clearfix;
		a{
			display: block;
			@extend .clearfix;
		}
		.logo-img{
			float: left;
			width: $logoImgwidth;
			max-width: $logoImgmaxWidth;
			height: $logoImgheight;
			max-height: $logoImgmaxHeight;
			vertical-align: middle;
		}
		.slogan{
			font-size: 13px;
			font-weight: 900;
			float: left;
			color: $textColor;
			text-align: center;
			padding: 2em 0.5em 0;
			text-transform: uppercase;
			width: calc(100% - #{$logoImgmaxWidth});
		}
	}
	.header-contacts {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		float: $heaContFloat;
		padding: $heaContPadding;
		width: $heaContWidth;
		padding-right: 15px;
   		padding-left: 15px;
		// @extend .clearfix;
		@include media(md) {
			justify-content: space-around;
		}
	}
	.phones {
		// float: left;
		padding: 0 0 0 2em;
		// padding: 0 1em 0 2em;
		// width: 22%;
	}
	.mail {
		// float: left;
		// padding: 0;
		// padding: 0 1em;
		// width: 32%;
		.mail-wrapper {
			padding: 0 0 0 2em;
		}
	}
	.times-block {
		// float: left;
		// padding: 0 0;
		// padding: 0 1em;
		// width: 23%;
	}
	.header-cart {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		// float: right;
		// padding: 0 0;
		// padding: 0 1em;
		// width: 23%;
		@include media(xs) {
			width: 100%;
			justify-content: center;
		}
	}
	.auth-block {
		.auth-box {
			padding: 7px 0 0;
			.reg {
				margin-right: 10px;
			}
			.enter {
			}
		}
	}
	@include media(lg) {
		.logo {
			// text-align: center;
			// width: 100%;
		}
		.header-contacts {
			// width: 100%;
			// padding: 0 1em;
		}
	}
	@include media(md) {
		.logo {
			text-align: center;
			// float: none;
			& > a {
				display: block;
			}
			.logo-img{
				display: inline-block;
				float: none;
			}
			.slogan{
				display: inline-block;
				vertical-align: middle;
				float: none;
			}
		}
	}
	@include media(md) {
		.header-contacts {
			.phones,
			.mail,
			.times-block,
			.header-cart{
				// width: 50%;
				// margin: 0;
				// min-height: 73px;
			}
			.mail,
			.times-block,
			.header-cart{
				// padding: 0;
			}
		}
	}
	@include media(xs) {
		.header-contacts {
			.phones,
			.mail,
			.times-block,
			.header-cart{
				// min-height: auto;
				// width: 100%;
			}
			.mail,
			.times-block,
			.header-cart{
				// padding: 0 0 1em;
			}
		}
	}
}
/* конец раскладки */
.header-bottom {
	.contact-block{
		order: 1;
	    border-bottom: 1px solid rgba(204, 204, 204, 0.41);
	    padding: 0 0 0.5em;
	    width: 100%;
	    + .container-bootstrap {
	    	padding-top: 1em;
	    	padding-bottom: 1em;
	    }
	}
	> .container-bootstrap {
		position: relative;
	}
	.phones,
	.mail,
	.times-block,
	.call-order-wrapper,
	.header-cart{
		@include media(lg) {
			margin-bottom: 0.5em;
		}
		@include media(md) {
		}
	}
	.phones {
		position: relative;
		.title {
			font-size: 15px;
			margin: 0 0 3px;
			text-transform: uppercase;
		}
		.icon {
			@include fontawesome('') {
				width: 24px;
				height: 24px;
				background: url(/assets/images/call.png) 0 0 no-repeat;
				background-size: contain;
				color: $iconColor;
			}
			a{
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 2;
			}
		}
	}
	.phones-list {
		display: flex;
		flex-wrap: wrap;
		// display: inline-block;
		list-style: none;
		margin: 0;
		position: relative;
		vertical-align: middle;
		@include media(xs) {
			display: block;
		}
	}
	.phones-list__item {
		&:nth-child(2){
			padding: 0 0 0 1em;
			@include media(xs) {
				padding: 0;
			}
		}
		&:nth-child(1){
			@include media(xs) {
			// padding: 0 1em 0 0;
			}
		}
		a {
			color: $textColor;
			display: block;
			// font-size: 16px;
			// font-weight: bold;
			// line-height: 36px;
			text-decoration: none;
			white-space: nowrap;
			&:hover{
				color: $linkColor--hover;
			}
		}
		.pref {
			// font-weight: normal;
		}
	}
	.mail {
		.mail-wrapper {
			position: relative;
			// margin: 0 0 0.7em;
			.icon {
				@include fontawesome('') {
					width: 24px;
					height: 24px;
					background: url(/assets/images/mail.png) 0 0 no-repeat;
					background-size: contain;
					color: $iconColor;
				}
				a{
					display: block;
					width: 100%;
					height: 100%;
					position: relative;
					z-index: 2;
				}
			}
			@include media(lg) {
				// margin: 0 0 0.5em;
			}
		}
	}
	.mail-link {
		white-space: nowrap;
		text-decoration: none;
		color: $textColor;
		&:hover{
			color: $linkColor--hover;
		}
	}
	.call-order {
		// width: 100%;
		// max-width: 200px;
		// text-align: center;
		// white-space: nowrap;
		cursor: pointer;
		&-wrapper{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			.call-order{
				display: flex;
				align-items: center;
			    margin: 0 4px;
			    &:hover{
			    	img{
			    		filter: none;
			    	}
			    }
			    img{
			    	width: 24px;
			    	height: 24px;
			    	margin: 2px 0 0;
			    	filter: grayscale(1);
			    }
			}
		}
		&.button{
			border-bottom: 3px solid $buttonBgColor1;
			&:hover{
				border-color: $buttonBgColor2--hover;
			}
			&:active {
				border-color: $buttonBgColor1--hover;
			}
		}
	}
	.times-block {
		position: relative;
		.title {
			font-size: 15px;
			margin: 0 0 3px;
			text-transform: uppercase;
			@include media(md) {
				padding: 0 0 0 2em;
			}
		}
		.icon{
			@include fontawesome('') {
				width: 24px;
				height: 24px;
				left: 0;
			    margin: -11px 0 0 0;
				background: url(/assets/images/clock.png) 0 0 no-repeat;
				background-size: contain;
				color: $iconColor;
			}
		}
	}
	.times {
		display: block;
		padding: 0 0 0 2em;
		position: relative;
		white-space: nowrap;
		@include media(md) {
		}
		& > span {
			font-weight: 900;
		}
	}
}
.header-cart {
	cursor: default;
	// padding: 0 0 5px;
	text-align: right;
	user-select: none;
	&.open {
		.cart {
			&.full {
				&:before {
					color: $iconColor;
				}
			}
		}
	}
	.cart {
		// display: inline-block;
		position: relative;
		text-align: left;
		white-space: nowrap;
		padding: 0 2em 0 0;
		// width: 100%;
		@include fontawesome('\f07a') {
			color: $iconColor;
			font-size: 24px;
			margin: -17px 0 0;
			left: 0;
		}
		&.empty{
			&:before{
				color: #808080;
			}
		}
		& > .header-cart__title-mob {
			display: none;
		}
		& > .header-cart__title {
			font-size: 15px;
			text-transform: uppercase;
		}
		.summ-block {
			display: none;
			padding: 0 0 0 2em;
		}
		& > .count {
			padding: 0 0 0 2em;
			& > .count-text {
				display: inline-block;
			}
			& > .count-quantity {
				display: inline-block;
			}
		}
	}
}
.desktop {
	.header-cart {
		.cart {
			&.full {
				cursor: pointer;
			}
			&:not(.empty) {
				&:hover {
					&:before {
						color: $linkColor;
					}
				}
			}
		}
	}
}
#progressBar{
	height: 3px;
	background: $linkColor;
	top: 0;
	left: 0;
	right: auto;
	bottom: auto;
	position: fixed;
	z-index: 9999;
	pointer-events: none;
}
.tofavorite_button{
	position: relative;
	width: 30px;
	// position: absolute;
	// top: 0;
	// right: 0;
	// padding: 0.5em 1em;
	&:hover{
		cursor: pointer;
	}
	@include fontawesome('\f004') {
		position: relative;
		font-size: 20px;
		left: 0;
		margin: -5px 5px 0 0;
		color: #808080;
	}
	.count{
		display: block;
		position: relative;
		width: 20px;
		height: 20px;
		line-height: 20px;
		border-radius: 50%;
		text-align: center;
		color: $white;
		background: $linkColor;
		z-index: 2;
	}
}
.connect{
	display: inline-block;
	// max-width: 200px;
	white-space: nowrap;
	// margin: 0.5em 0 0;
	// @extend .clearfix;
	a{
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
		overflow: hidden;
		z-index: 0;
		transition: width 1s ease;
		margin: 0 4px;
		img{
			width: 100%;
	    	filter: grayscale(1);
		}
		&.telegram{
			// width: 24px;
		}
		&.whatsapp{
		}
		&:hover{
		img{
	    	filter: none;
		}
			// z-index: 1;
			// transition: width 0.5s ease;
			// &.viber{
			// 	width: 55px;
			// }
			// &.telegram{
			// 	width: 78px;
			// }
			// &.whatsapp{
			// 	width: 80px;
			// }
			// &.instagram{
			// 	width: 80px;
			// }
		}
	}
}
html{
	&.flexbox{
		.header-bottom{
			.logo{
				// display: flex;
				// align-items: center;
				&:before,
				&:after{
					// display: none;
				}
			}
		}
	}
}