:root{
	--primary-color:#0a276b;
	--white-color:#ffffff;
	--black-color:#000219;
	--para-color:#8b8787;
	--light-bg:#cccccc24;
	--border-color:#cccccc47;
	--font16:16px;
	--font18:18px;
	--font22px:22px;
	--font25px:25px;
	--font35px:35px;
	--font-weight300:300;
	--font-weight500:500;
	--font-weight600:600;
	--transition: all 0.5s;
	@include media(md) {
		--font35px:20px;
		--font25px:15px;
		--font22px:18px;
	}
}

.module {
	@include module(false);
	.filter-wrapper,
	.vote-block{
		padding: 1em;
	}
	.news-block{
		padding: 1em 1em 0;
	}
}
/* Оформление модулей в контент области */
.module-inner {
	clear: both;
	// margin: 1em 0 2em;
	&.catalog{
		.container-bootstrap{
			padding: 0;
		}
	}
	& > .module-header {
		background: none;
		padding: 0 0 40px;
		margin: 0;
		text-align: center;

		& > span,
		& > h2 {
			font-size: var(--font35px);
			color: $textColor;
			padding: 0 0 10px;
			// text-transform: capitalize;
			a{
				color: $textColor;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
	&.services,
	&.new,
	&.hit,
	&.spec,
	&.prem_reviews,
	&.partners{
// padding: 1em 0 0;
// border-top: 1px solid #ececec;
}
&.prem_reviews,
&.partners{
	& > .module-header {
		& > span,
		& > h2 {
// text-align: left;
}
}
.all_reviews{
	padding: 1em 0;
	text-align: right;
}
}
&.news-block{
	.news-all,
	.news-archive{
		text-align: right;
		a{
// padding: 0 1em;
}
}
}
}
#yamap{
	.module-header{
		margin: 0 auto;
		max-width: $containerWidth;
		background: $white;
	}
}
.module-wrapper {
	position: relative;
}
/* Бренды */
.module.brands {

	.brands-list {
		display: block;
		list-style: none;
		padding: 1em;
	}

	.brands-list__item {
		font-size: 1.14286em;
		line-height: 1.9;

		& > span {
			color: $linkColor--hover;
		}
	}

	.brands-list__link {
		color: $linkColor;
		transition: color 0.3s ease;

		&:hover {
			color: $linkColor--hover;
		}
	}
}
.module.brands {
	@include media(md) {
		float: left;
		padding: 0 0.5em 0 1em;
		width: 50%;
	}
	@include media(sm) {
		float: none;
		padding: 0;
		width: 100%;
	}
}
.mobile,
.tablet {
	&.index{
		.module-inner{
			&.photo-block{
				.module-header{
					@include media(md) {
						margin-left: 15px;
						margin-right: 15px;
					}
				}
			}
		}
	}



	section{
		.module-inner {
			padding: 1em 0;
		}
	}
	.module,
	.module-inner {
		background: none;
		margin: 1em 0 0;
		& > .module-header {
			background: $moduleMobileHeaderBg;
			border-radius: $borderRadius;

			&.toggle{
				position: relative;
				@include fontawesome('\f078') {
					font-size: 14px;
					color: $white;
					left: inherit;
					right: 1em;
					margin: -10px 0 0;
				}
				&.active{
					&:before{
						content: '\f077';
					}
				}
			}
			& > span,
			& > h2 {
				color: $moduleMobileHeaderColor;
				& > a {
					text-decoration: none;
					color: $moduleMobileHeaderColor;
				}
			}
			@include media(md) {
				padding: 0.5em 1em;
				& > span,
				& > h2 {
					font-size: 16px;
					@include media(md) {
						padding: 0;
					}
				}
				> p{
					color: $white;
				}
			}
		}
		&.prem_reviews,
		&.partners{
			& > .module-header {
				& > span,
				& > h2 {
					text-align: center;
				}
			}
			.all_reviews{
				text-align: center;
			}
		}
		&.photo-block{
			.module-header{
				@include media(md) {
					// margin-left: 15px;
					// margin-right: 15px;
				}
			}
		}
		.module-wrapper {
			padding: 0;
			margin: 0 0 1em;
		}
		.module-header{
			margin: 0 0 1em;
		}
	}
	.main-left-menu{
		margin: 0 0 1em;
		.module-header{
			margin: 0;
		}
		.module-wrapper {
			@include media(md) {
				display: none;
			}
		}
	}
}

html.index .prem_reviews .item{
	background-color: rgb(255, 255, 255, 0.2);
}
.prem_reviews{
	&.module-inner{
		> .module-header{
			h2{
				color: $white;
			}
		}
	}
	.item{
		text-align: center;
		background-color: rgb(0, 0, 0, 0.8);
		padding: 40px 20px;
		border-radius: 10px;
		&:hover{
			box-shadow: 0 0 10px rgba(0,0,0,.1);
		}
		.desc{
			color: rgb(255, 255, 255, 0.65);
		}
		.full_review{
			color: $white;
			text-decoration: none;
			cursor: pointer;
			&:hover{
				text-decoration: underline;
			}
		}
		img {
			width: 63px;
			max-height: 63px;
			min-height: 63px;
			border-radius: 50px;
			object-fit: cover;
			object-position: top;
		}
		.h2 {
			color: #ffffff;
			font-size: 22px;
			padding: 25px 0px 10px;
		}
		.top{
			display: flex;
			align-items: center;
			b{
				padding: 0 1em;
			}
		}
		.bottom{
			.bottom_text{
				padding: 1em 0;
				min-height: 100px;
			}
			.bottom_date{
				font-weight: 700;
				margin: 0 0 0.5em;
			}
		}
	}
	.all_reviews{
		text-align: center;
	}
}

.partners{
	.item{
		border: 1px solid rgba(204, 204, 204, 0.51);
		padding: 30px 30px;
		border-radius: 10px;
		background-color: #ffffff;
		transition: all 0.5s;
		img{
			display: block;
			margin: 0 auto;
		}
	}
}

.reviews_page{
	padding: 0 1em;
	.item{
		padding: 1em 0;
		border-bottom: 1px solid #ececec;
		&:nth-child(2n){
			background: $white;
		}
	}
}

.order-block {
	background: #fbfbfb;
	border: 1px solid #f2f2f2;
	color: #555;
	clear: both;
	font-size: 1em;
	margin: 30px 0 40px;
	padding: 2rem 0;
	width: 100%;
}
.order-block td {
	padding: 42px 0 37px;
	vertical-align: top;
	float: none;
	display: table-cell;
}
.order-block{
	.text {
		padding: 0 0 0 131px;
		min-height: 46px;
		line-height: 24px;
		color: #555;
		text-align: left;
		position: relative;
		@include media(sm) {
			padding: 0;
			text-align: center;
		}
		.svg {
			display: block;
			width: 48px;
			height: 58px;
			position: absolute;
			top: 39px;
			top: -2px;
			left: 52px;
			@include media(sm) {
				display: none;
			}
		}
	}
	.btns{
		text-align: center;
		margin: 1em 0 0;
	}
}

.btn.btn-default {
	background-color: #ff6d00;
	border-color: #ff6d00;
	color: #ffffff;
}

.btn, .btn.btn-lg {
	font-size: 1em;
}

.reviews_page .item {
	padding: 1rem 0;
}

.padded-bottom {
	padding-bottom: 1rem;
}

.uploader {
	border: 1px solid #e8e8e8;
	padding: 9px;
	border-radius: 2px;
	height: 52px;
	position: relative;
	margin-bottom: -1px;
}

.uploader input[type=file] {
	z-index: 10;
	opacity: 0;
	border: none;
	background: 0 0;
	position: absolute;
	top: 0;
	right: 0;
	float: right;
	cursor: default;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.uploader .filename {
	overflow: hidden;
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	font-size: 13px;
	color: #9aa6ae;
	position: relative;
	top: -5px;
	padding: 9px 9px 9px 145px;
	text-overflow: ellipsis;
}

.uploader .action {
	position: absolute;
	top: 9px;
	left: 9px;
	background: #fff;
	border-radius: 3px;
	color: #fff;
	padding: 7px 12px;
	font-size: 12px;
	line-height: 15px;
	margin-right: 10px;
	color: #ff6d00;
	border: 1px solid #ffd3b3;
}
.uploader:hover .action {
	background-color: #ff7f1f;
	border-color: #ff7f1f;
	color: #222;
}
.fadeFon {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0,0,0,.5);
	z-index: 999;
}
.float_window {
	max-width: 410px;
	width: 100%;
	position: absolute;
	top: 10%;
	left: 50%;
	margin: 0 0 0 -205px;
	background: #fff;
	padding: 2em 0 1em;
	@include media(sm) {
		width: calc(320px - 2em);
		margin: 0 0 0 calc(160px - 320px + 1em);
	}
	#fz152{
		+ label{
			cursor: pointer;
			font-size: 11px;
			display: inline-block;
			line-height: 1.333;
			padding: 0 0 18px 24px;
			margin: 0 0 -18px;
		}
	}
	.g-recaptcha{
		margin: 0 0 1em;
		@include media(sm) {
			margin: 0 0 0 -20px;
		}
	}
}
.float_window .form-item input[type="text"],
.float_window .form-item input[type="email"],
.float_window .form-item textarea {
	width: 100%;
	padding: .5rem;
	resize: none;
}

.float_window .form-item textarea {
	height: 125px;
}

.close_this_window {
	position: absolute;
	top: .5rem;
	right: 1.5rem;
	font-family: arial;
	cursor: pointer;
}

.add_file span{
	border-bottom: 1px dotted;
}
.add_file:hover,
.add_file:hover::before,
.close_this_window:hover {
	color: #fd6a1a;
}
.reds {
	color: #ff0000;
}

.add_file {
	color: #333;
	font-size: 12px;
	cursor: pointer;
	padding: 4px 10px 0;
	display: inline-block;

}
.add_file:before {
	content: "+";
	text-align: center;
	display: inline-block;
	width: 7px;
	height: 7px;
	margin: 0 5px 1px 0;
}
.hidden {
	display: none;
}

.tisers{
	> .container{
		background: $white;
		padding: 2em 0 1em;
		display: flex;
		justify-content: space-between;
		@include media(lg) {
			justify-content: center;
			flex-wrap: wrap;
		}
		&:before,
		&:after{
			display: none;
		}
	}
	.item{
		padding: 0 1em;
		flex: 1 1 100%;
		@include media(lg) {
			padding: 0 1em 1em;
			flex: 1 1 33.333%;
		}
		@include media(sm) {
			flex: 1 1 50%;
		}
		a{
			display: flex;
			text-decoration: none;
			@include media(slg) {
				align-items: center;
				flex-direction: column;
			}
		}
		&_img{
			width: 58px;
			height: 50px;
			margin: 0 20px 0 0;
			@include media(slg) {
				margin: 0;
			}
			img{
				max-width: 58px;
				max-height: 50px;
			}
		}
		&_title{
			font-size: 12px;
			color: $textColor;
		}
	}
}

html.index .hs_section2_flex {
	margin: -50px 0 0;
	@include media(md) {
		margin-top: 60px;
	}
}
html.index .hs_section2_flex .hs_section2_item{
	padding: 0 20px 30px;
}
html.index .section_media{
	margin: -50px auto 0;
}
.hs_section2_flex {
	position: relative;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
	gap: 15px;
	z-index: 1;
	@include media(md) {
		margin-top: 0;
		grid-template-columns: repeat(auto-fit, minmax(137px, 1fr));
	}
	a{
		text-decoration: none;
	}
	.hs_section2_item  {
		background-color: rgba(255,255,255, 1);
		padding: 20px 20px 30px;
		border-radius: 6px;
		text-align: center;
		box-shadow: 0 18px 30px 0 rgba(177,193,224,.3);
		@include media(md) {
			padding: 10px;
		}
		h3 {
			margin: 15px 0 0;
			padding: 0;
			font-size: 17px;
			color: $textColor;
			@include media(md) {
				font-size: 11px;
			}
		} 
	}
}
.section_media {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	border-radius: 50px;
	margin: 0 auto;
	background: $linkColor;
	@include media(md) {
		width: 50px;
		height: 50px;
	}
	img{
		@include media(md) {
			max-width: 25px;
		}
	}
}

a.ks-all-btn {
	color: $white;
	font-size: 18px;
	// text-transform: capitalize;
	background: $linkColor;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 150px;
	transition: all 0.5s;
	height: 50px;
	border-radius: 100px;
	text-decoration:none;
	@include media(md) {
		margin: 0 auto;
		height: auto;
		padding: 5px;
		max-width: 120px;
	}
}
a.ks-all-btn:hover{
	transform: translateY(-3px);
}


/* about css start */
html.index .ks-about-wrapper {
	padding: 70px 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	@include media(md) {
		padding: 0;
	}
}
.ks-about-img {
	position: relative;
	overflow: hidden;
	margin: 0 0 30px 0;
	background: $white;
	border-radius: 10px;
	box-shadow: 0 18px 30px 0 rgba(177,193,224,.3);
		@include media(md) {
			padding: 0 0 1em;
		}
}
.ks-about-overlay {
	display: none;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.2);
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 10px;
}
.ks-about-img img {
	display: block;
	width: 55%;
	max-width: 450px;
	max-height: 350px;
	min-height: 350px;
	object-fit: contain;
	object-position: left center;
	border-radius: 10px;
	padding: 0 10px;
	@include media(md) {
		max-width: 100%;
		max-height: 265px;
		min-height: 265px;
		opacity: 0.5;
	}
	@include media(sm) {
		max-height: 165px;
		min-height: 165px;
	}
}
html.index .ks-about-content{
	max-width: 240px;
	@include media(md) {
		min-width: 100%;
	}
}
html.index .ks-about-content .h1 {
	font-size: var(--font35px);
	@include media(md) {

	}
}
.ks-about-content {
	position: absolute;
	right: 20px;
	top: 50px;
	width: 100%;
	max-width: 190px;
	@include media(md) {
		right: 0;
		left: 0;
		bottom: auto;
		max-width: 100%;
		top: 0;
		padding: 20px 15px;
		text-align: center;
	}
}
.ks-about-content .h6 {
	font-size: var(--font25px);
	letter-spacing: 3px;
	padding: 0 0 10px;
	// text-transform: capitalize;
	@include media(md) {

	}
}
.ks-about-content .h1 {
	font-size: 25px;
	margin: 0;
	line-height: normal;
	padding: 0 0 20px;
	// text-transform: capitalize;
	font-weight: 600;
	@include media(md) {
		padding: 0 0 15px;
		font-size: 25px;
	}
}

/* ========================= about css start ========================== */
.idf-about-wrapper {
	padding: 70px 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	@include media(md) {
		padding: 0 15px;
	}
}
html.index .idf-about-content .h2 {
	font-size: var(--font35px);
}
.idf-about-content .h2 {
	font-size: 25px;
	font-weight: 800;
	color: $linkColor;
	padding: 0 0 10px;
}

.idf-about-content p {
	font-size: 18px;
	padding: 0 0 10px;
}
.idf-about-content > h3 {
	color: $linkColor;
	font-size: var(--font22px);
	font-weight: 700;
	padding: 25px 0 20px;
}
.idf-red{
	color: $linkColor;
}
ul.idf-banner-ul {
	padding: 0px 0 15px;
}
ul.idf-banner-ul li {
	position: relative;
	padding: 0 0 20px 30px;
	font-size: 18px;
	color: $borderColor;
	display: inline-block;
	vertical-align: top;
	width: 40%;
	@include media(md) {
		display: block;
		width: 100%;
	}
}
ul.idf-banner-ul li img {
	position: absolute;
	left: 0;
	top: 2px;
	width: 24px;
	max-height: 24px;
	min-height: 24px;
	object-fit: cover;
	object-position: top;
}
/* ===================== service css start============================ */
html.index .idf-feature-wrapper {
	padding: 0 0 40px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color:var(--light-bg);
	@include media(md) {
		padding: 0 15px;
	}
}
html.index .idf-all-heading {
	margin: 65px auto 40px;
		@include media(md) {
		margin: 1em auto;
	}
}
.idf-all-heading {
	text-align: center;
	width: 100%;
	// max-width: 570px;
	@include media(md) {
		margin: 0 auto;
	}
}
.idf-all-heading .h2{
	font-weight: 800;
	font-size: var(--font35px);
	color: #222222;
	padding: 0 0 15px;
	// text-transform: capitalize;
}
.idf-all-heading.idf-all-heading-white .h2 {
	color: #222222;
}
.idf-all-heading p{
	font-size: var(--font18);
}
.idf-service-box {
	min-height: calc(100% - 20px);
	background-color: var(--white-color);
	transition: var(--transition);
	padding: 25px;
	margin-bottom: 20px;
	text-align: center;
	box-shadow: 0px 11px 61px 3px rgba(34, 34, 34, 0.09);
}
.ldf-feature-shape {
	width: 100%;
	max-width: 100px;
	margin: 0 auto 10px;
	background-color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	height: 100px;
}
.idf-service-box img{
	width:60px;
	max-height: 60px;
	min-height: 60px;
	object-fit: cover;
	object-position: top;
}
.idf-service-box > .h2,
.idf-service-box > .h2 > a{
	color:#222222;
	font-size: var(--font18);
	padding: 20px 0px 10px;
	display: block;
	font-weight: 700;
	// text-transform: capitalize;
	text-decoration: none;
	transition: var(--transition);
	@include media(md) {
		padding: 0;
	}
}
.idf-service-box p{
	color: var(--para-color);
}
.idf-service-box > a {
	margin: 20px auto 0;
}
.idf-service-box:hover{
	transform: translateY(-5px);
}
.idf-service-box:hover .h2 > a{
	color: var(--primary-color);
}



/* ===================== price css start============================ */
.index{
.hrr-price-wrapper {
  padding: 80px 0px 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color:var(--primary-color);
	}
	.hrr-all-heading.hrr-all-heading-white .h2 {
  color: #ffffff;
}
.hrr-all-heading.hrr-all-heading-white p {
  color: #ffffffbf;
}
}
.hrr-price-wrapper {
		@include media(md) {
		padding: 1em 0 !important;
	}
}
.hrr-all-heading {
  text-align: center;
  width: 100%;
  // max-width: 560px;
  margin: 0 auto 70px;
  		@include media(md) {
		  margin: 0 auto 1em;
	}
}
.hrr-all-heading .h2{
  font-size: var(--font35px);
  color: var(--black-color);
  padding: 0 0 15px;
  // text-transform: capitalize;
}

.hrr-all-heading p{
  font-size: var(--font18);
}

.hrr-price-box {
  background-color: var(--white-color);
  transition: var(--transition);
  padding: 25px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0px 11px 61px 3px rgba(34, 34, 34, 0.09);
  min-height: calc(100% - 20px);
}
.hrr-price-box img{
  width: 100%;
  max-width:70px;
  max-height: 70px;
  min-height: 70px;
  object-fit: cover;
  object-position: top;
  margin: 0 auto;
}
.hrr-price-box > .h2{
  color: var(--black-color);
  font-size: 22px;
  padding: 20px 0px 10px;
  display: block;
  // text-transform: capitalize;
  transition: var(--transition);
}
.hrr-price-box p{
  color: var(--para-color);
}
.hrr-price-box .h3{
	font-weight: bold;
	font-size: var(--font22px);
}
.hrr-price-box > a {
  margin: 20px auto 0;
}
.hrr-price-box:hover{
  transform: translateY(-5px);
}
.hrr-price-box:hover .h2{
  color: var(--primary-color);
}