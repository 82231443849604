.podpiska_form {
	.form_block{
		margin: 0;
	}
	.form_content{
		// padding: 0 1em;
	}
	.button{
		width: 100%;
		border-radius: 0;
		padding: 10px 1em;
	}
	.input-text{
		margin: 0 auto;
		padding: 10px 1em;
		height: inherit;
		width: 100%;
	}
	.form_block{
		+ div{
			text-align: center;
			padding: 1em 0;
		}
	}
}

.newsletter{
	background-color: rgba(46, 46, 46, 0.8);
    padding: 20px;
}