footer {
	.h3 {
		padding: 15px 0 10px;
		font-size: 20px;
		color: $white;
	}
	.logo-text{
		color: $white;
	}
	.title{
		color: $white;
		font-size: 22px;
		text-align: left;
		padding: 5px 0 25px;
	}
	.icon {
		position: relative;
		width: 40px;
		min-width: 40px;
		height: 40px;
		margin: 0;
		background: $linkColor--hover;
		border-radius: 50px;
	}
}

/* Раскладка футера */
.footer-top {
	&.inline{
		.logo{
			float: $logoPos;
			width: $logoWidth;
			margin: $logoMargin;
			padding: $logoPadding;
			@include media(lg) {
				width: 100%;
			}
			@include media(xmd) {
				float: none;
				text-align: center;
			}
			a{
				display: block;
			}
			.logo-img{
				float: left;
				width: $logoImgwidth;
				max-width: 180px;
				height: $logoImgheight;
				max-height: 58px;
				vertical-align: middle;
				@include media(xmd) {
					display: inline-block;
					float: none;
				}
			}
		}
		.contacts{
			float: $heaContFloat;
			padding: $heaContPadding;
			width: $heaContWidth;
			@extend .clearfix;
			@include media(lg) {
				width: 100%;
				padding: 0 1em;
			}
			.phone {
				float: left;
				width: 33.333%;
				@include media(xmd) {
					width: 100%;
				}
			}
			.mail {
				float: right;
				width: 33.333%;
				@include media(xmd) {
					width: 100%;
				}
			}
			.adres {
				float: right;
				width: 33.333%;
				@include media(xmd) {
					width: 100%;
				}
			}
		}
	}
	.logo{
		.logo-img{
		width: $logoImgwidth;
			max-width: $logoImgmaxWidth;
	}
	}
	.logo, .logo a{
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-decoration: none;
		@include media(md) {
			flex-wrap: wrap;
			justify-content: center;
		}
	.logo-text{
		padding: 0 1em;
		text-align: center;
	}
	}
	.contacts {
		// float: left;
		// padding: 0 1em;
		// width: 33.333%;
		.logo{
			display: inline-block;
			margin: 0 0 0.5em;
			@extend .clearfix;
			a{
				display: inline-block;
				@extend .clearfix;
			}
			.logo-img{
				float: left;
				width: $logoImgwidth;;
				max-width: $logoImgmaxWidth;
				height: $logoImgheight;
				max-height: $logoImgmaxHeight;
				vertical-align: middle;
				@include media(md) {
					display: block;
					float: none;
					margin: 0 auto;
				}
			}
		}
		@include media(md) {
			width: 100%;
			text-align: center;
			> div{
				// display: block;
			}
			.logo{
				width: 100%;
			}
			.phone{
				> ul{
					> li{
						display: inline-block;
						&:first-child{
							// margin: 0 10px 0 0;
						}
					}
				}
			}
			@include media(md) {
				.phone{
					> ul{
						> li{
							display: block;
						}
					}
				}
				.icon{
					left: 0;
				}
			}
		}
	}

	.footer-menu {
	    padding: 0 0 0 1em;
		// float: right;
		// padding: 0 1em;
		// width: 66.666%;
		@include media(md) {
			padding: 0;
			// width: 100%;
		}
	}
}



.footer-bottom {

	.payments {
		// float: left;
		padding: 0 1em;
		// width: 25%;
	}

	.social-block {
		// float: left;
		padding: 0 1em;
		// width: 35%;
	}

	.counters {
		// float: right;
		padding: 0 1em;
		// width: 30%;
	}
}


/* Оформление */
.footer-top {
	background-image: $footerTopBgColor;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 70px 0px;
	// border-top: 3px solid $linkColor;

	.footer-menu {
		// margin: 1.5em 0 0;

		// @extend .clearfix;


		.title {
			// color: $white;
			// font-weight: bold;
			// margin: 0 0 0.5em;
			// text-transform: uppercase;
		}

		.ishop,
		.text {
			// float: left;
			// width: 50%;
			@include media(xs) {
				// width: 100%;
				// text-align: center;
				// margin: 0 0 1em;
			}
			.item {
				position: relative;
				padding: 0 0 0 2em;
				transition: var(--transition);
				&:before{
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					width: 16px;
					height: 16px;
					transform: translate(0, -50%);
					background: url(/assets/images/footer-arrow.png) 0 0 no-repeat;
				}
				&:hover {
					transform: translateX(-5px);
				}
				& > a {
					color: $white;
					text-decoration: none;
					line-height: 1.666;
					&:hover{
						color: $linkColor--hover;
					}
				}

				&.selected {
					& > a {
						color: $linkColor--hover;
						cursor: default;
						text-decoration: none;
					}
				}

				&.more{
					& > a {
						color: $linkColor--hover;
						text-decoration: underline;
						&:hover{
							color: $linkColor--hover;
						}
					}
				}
			}
		}
	}


	.adres {
		display: flex;
		align-items: center;
		margin: 0 0 1em;
		position: relative;
		// padding: 0 1em 0 2em;

		a{
			padding: 0 0 0 1em;
			color: $white;
			&:hover{
				color: $linkColor--hover;
			}
		}

		.icon {
			@include fontawesome('\f041') {
				color: $black;
				margin: 0;
				transform: translate(-50%, -50%);
			}
		}
	}


	.phone {
		display: flex;
		align-items: center;
		margin: 0 0 0.5em;
		position: relative;

		.icon {
			vertical-align: top;

			@include fontawesome('\f095') {
				color: $black;
				margin: 0;
				transform: translate(-50%, -50%);
			}
		}

		& > ul {
			display: inline-block;
			list-style: none;
			padding: 0 0 0 1em;

			& > li {
				font-size: 18px;
				font-weight: bold;
				color: $white;
				a{
					color: $white;
					text-decoration: none;
					&:hover{
						color: $linkColor--hover;
					}
				}
				.pref {
					font-weight: normal;
				}
			}
		}


	}

	.mail {
				display: flex;
		align-items: center;
		margin: 0 0 1em;
		position: relative;

		a{
			padding: 0 0 0 1em;
			color: $white;
			&:hover{
				color: $linkColor--hover;
			}
		}

		.icon {
			@include fontawesome('\f0e0') {
				color: $black;
				margin: 0;
				transform: translate(-50%, -50%);
			}
		}
	}
}


.social-block {
	margin: 0.5em 0;
}

.social-block__item {
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	height: 40px;
	width: 40px;
	margin: 0 0.5em 0 0;
	background: $linkColor--hover;

	&:hover{
		background: $linkColor;
		& > a {
			&:before{
				color: $white;
			}
		}
	}

	& > a {
		text-align: center;
		height: 40px;
		width: 40px;
		display: inline-block;
		position: relative;
		line-height: 40px;
		text-decoration: none;
		vertical-align: middle;


		@include fontawesome('') {
			color: $black;
			font-weight: 300;
			font-size: 24px;
			margin: -20px auto 0;
			left: 0;
			right: 0;
		}

		&.si1:before {
			content: '\f189';
		}
		&.si2:before {
			content: '\f263';
		}
		&.si3:before {
			content: '\f09a';
		}
		&.si4:before {
			content: '\f099';
		}
		&.si5:before {
			content: '\f1a0';
		}
		&.si6:before {
			content: '\f1fa';
		}
		&.si7:before {
			content: '\f167';
		}
		&.si8:before {
			content: '\f16d';
		}
	}
}


.footer-bottom {
	background: $footerBottomBgColor;
	padding: 0.25em 0;
	.container{
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:before,
		&:after{
			display: none;
		}
	}
	@include media(xmd) {
		.container{
			text-align: center;
			> div{
				width: 100%;
			}
		}
	}

	.payments {
		margin: 7px 0 0;

		& > img {
			max-width: 290px;
			width: 100%;
		}
	}

	.counters {
		margin: 0.25em 0 0;
		text-align: right;
		@include media(md) {
			text-align: center;
		}

		& > a {
			display: inline-block;
			vertical-align: middle;
		}

		& > .citron-logo__link {
			display: inline-block;
			max-width: 92px;
			width: 100%;
			position: relative;
			vertical-align: middle;

			&:hover {
				.citron-logo--hover {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.citron-logo--hover {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: opacity 0.3s ease;
			visibility: hidden;
		}
	}
}

.map{
	float: left;
	width: 10%;
	margin: 0.5em 0;
	padding: 0 1em;
	@include media(xmd){
		width: 100%;
	}
}
.copyright{
	color: $white;
	// float: left;
	// margin: 0.5em 0;
	// padding: 0 1em;
	@include media(xmd){
		// width: 100%;
	}
}