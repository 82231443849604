  .photo-carousel,
  .tri-slider{
    .photo-item{
      text-align: center;
      color: #95989b;
      h2{
      	padding: 0;
      }
      a, img{
      	text-align: center;
      	display: block;
      	margin: 0 auto;
      }
    }
  }

.gallery-box{
	position: relative;
	margin-bottom: 30px;
	transition: all 0.5s;
	a{
		position: relative;
		display: block;
	}
	img {
		width: 100%;
		max-width: 306px;
		max-height: 306px;
		min-height: 306px;
		object-fit: cover;
		object-position: top;
		border-radius: 10px;
	}
	&:hover{
		.gallery-overlay,
		.gallery-text{
			opacity: 1;
			visibility: visible;
		}
	}
}

.gallery-overlay{
    position: absolute;
    left: 0;
    right: 0;
    top: 5%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    max-width: 90%;
    margin: 0 auto;
    min-height: 90%;
    max-height: 90%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
}

.gallery-text{
	color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    z-index: 1;
    text-align: center;
}

.photos{
	.photo-item{
		a{
			img{
				// width: 100%;
			}
		}
	}
}
.photos-slider {
	.photo-item {
		& > a {
			display: block;
		}
	}
}



.albums_list,
.photos_list {
	@extend .clearfix;
	text-align: center;
	margin: 0px -5px 0;
}

.album_block,
.photo_block {
	display: inline-block;
	text-align: center;
	margin: 0 5px 10px;
	vertical-align: top;
}

.photo_block {
	max-width: 175px;
}

.album_block {
	max-width: 221px;
}

.album_photo img,
.photo_photo img{
	display: block;
}

.album_title {
	font-size: 14px;
	font-weight: bold;
	line-height: 20px;
	padding: 5px 0;
}

.photo_description {
	font-size: 13px;
	line-height: 15px;
	padding: 5px 0;
}

.gallery-photo {
	margin: 1em 0 0;
	padding: 0 2em;

	.owl-nav {

		&.disabled {
			display: block;
		}

		.owl-next {
			right: -0.75em;
		}

		.owl-prev {
			left: -0.75em;
		}

		.owl-prev,
		.owl-next {
			color: #30a12a;
			font-size: 3em;
			font-weight: bold;
			opacity: 0.5;

			&:hover {
				opacity: 1;
				transition: opacity 0.2s ease;
			}
		}
	}

	.photo-item {

		& > a {
			& > img {
				display: block;
				height: auto;
				max-width: 240px;
				width: 100%;
			}
		}
	}


	@include media(429px) {
		padding: 0 1em;
	}

	@include media(367px) {
		padding: 0 0.5em;
	}
}


.photo_block,
.album-block {
	float: left;
	margin: 0 1em 1em 0;
	width: calc(25% - 0.75em);

	&:nth-child(4n + 4) {
		margin: 0 0 1em;
	}

	.photo_photo,
	.album-photo {

		& > a {
			& > img {
				display: block;
				height: auto;
				max-width: 360px;
				width: 100%;
			}
		}
	}

	.album-title {
		padding: 0 0.5em;
		margin: 0.5em 0 0;
		text-align: center;

		& > a {
			font-size: 14px;
			margin: 0.5em 0;
		}
	}

	@include media(lg) {
		width: calc(50% - 0.5em);

		&:nth-child(4n + 4) {
			margin: 0 1em 1em 0;
		}

		&:nth-child(2n + 2) {
			margin: 0 0 1em;
		}
	}


	@include media(md) {
		width: calc(25% - 0.75em);


		&:nth-child(2n + 2) {
			margin: 0 1em 1em 0;
		}

		&:nth-child(4n + 4) {
			margin: 0 0 1em 0;
		}
	}

	@include media(550px) {
		width: calc(50% - 0.5em);

		&:nth-child(4n + 4) {
			margin: 0 1em 1em 0;
		}

		&:nth-child(2n + 2) {
			margin: 0 0 1em;
		}
	}

	@include media(380px) {
		margin: 0 0 1em;
		width: 100%;

		&:nth-child(4n + 4) {
			margin: 0 0 1em;
		}
	}
}


.photos_text {
	font-size: 14px;
}


.prev__photo2, .next__photo2, .prev__photo2:active, .next__photo2:active{
	background: linear-gradient(to bottom, #ada4ff, #ada4ff);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ada4ff, endColorstr=#ada4ff, GradientType=0);
	background-color: #ada4ff;
}


.flexbox {
	.albums,
	.photos{
		&_list{
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			&:before,
			&:after{
				display: none;
			}
			.album,
			.photo{
				&_block{
					width: 33.333%;
					max-width: inherit;
					margin: 0 0 1em;
					padding: 0 1em;
					@include media(xmd) {
						width: 50%;
					}
					@include media(sm) {
						width: 100%;
					}
				}
				&_photo{
					a{
						display: block;
						img{
							margin: 0 auto;
							width: 100%;
						}
					}
				}
			}
		}
	}
}